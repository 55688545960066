body {margin: 0;
    --white: #FCFDFD;
    --blue: #29536B;
    --green: #4CBA63;
    --grey: #A3AEB4;
    --black: #172123;
    box-sizing: content-box;
    overflow-x: hidden;
    max-width: 100vw;
    font-family: Century-Gothic, CenturyGothic, AppleGothic, sans-serif;;
    font-weight: 300;
    background-color: var(--white) ;
    color: var(--black);
}
h2{
    color: var(--blue);
}
.grid {
    display: grid;
}
nav {
    position: sticky;
  top: 0px;
}

ul.topnav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: var(--white);
  height: 10vh;
}

#navcontacts {
    border-color: var(--grey);
    border-width: 3px;
    border-style: none double;
    height: 10vh;
    width: 25vw;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    font-size: 1.5em;
}
#navcontacts a {
    margin-top: 2.5vh;
    padding: 0;
    color: var(--blue);
}
#navcontacts a svg {
    width: 90%;
    margin: auto;
}

ul.topnav li {
    float: right;
    height: 100%;}

ul.topnav li a {
    height: 90%;
  display: block;
  color: var(--black);
  text-align: center;
  margin: auto;
  text-decoration: none;
}

ul.topnav li.left {float: left;}

.topnav img {
    height: 100%;
    margin-left: 3vw;
    margin-top: 4px;
}
.navList {
    display: none;
}
.dropdown {
    float: left;
    overflow: hidden;
  }
  
  .dropdown .dropbtn {
    font-size: 1.5em;  
    border: none;
    outline: none;
    color: var(--blue);
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    height: 10vh;
    margin: 0;
    min-width: 20vw;
  }
  
  .navbar a:hover, .dropdown:hover .dropbtn {
    background-color: var(--grey);
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    right: 0px;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    float: none;
    color: var(--black);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {
    background-color: var(--grey);
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }



/* HERO */
#welcomeHero {
    height: 70vh;
    max-width: 100%;
    background-image: linear-gradient(rgba(250, 250, 250, 0.7), rgba(250, 250, 250, 0.7)), url(../img/andrej-lisakov-3A4XZUopCJA-unsplash.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 15vh;
    text-align: center;
}
#welcomeHero img{
    max-width: 85vw;
}
#welcomeHero h1 {
    font-weight: 200;
    margin: 0px;
}
#welcomeHero button {
    background-color: var(--green);
	border-radius: 28px;
	border:1px solid #368547;
	display:inline-block;
	cursor:pointer;
	color: var(--white);
	font-size:17px;
	padding:16px 31px;
	text-decoration:none;
	text-shadow:0px 1px 0px;
    margin: 5vh 0px;
}
/* ABOUT */
#about {
    width: 100%;
    text-align: center;
}
#about h2 {
    margin: 2.5vh auto;
}
#about img {
    width: 75%;
    box-shadow: #172123 3px;
}
#about p {
    text-align: left;
    width: 90%;
    margin: 2.5vh auto;
}
/* Contact */

#landingContact {
    background-color: var(--blue);
    color: var(--white);
    text-align: center;
}
#landingContact h2 {
    margin-top: 5vh;
    color: var(--white)
}
#landingContact div {
    text-align: left;
    margin: auto;
}
#blurb {
    max-width: 90%;
}
#landingContact ul :nth-child(3) {
    margin-bottom: 5vh;
}
#landingContact li {
    list-style-type: none;
    margin: 10px 0px;
}
#landingContact a {
    color: var(--white);
}
#landingContact svg {
    margin-right: 10px;
}
#landingContact ul {
    padding-left: 0px;
}
/* FOOTER */
#footer div {
    margin: auto;
    width: 90%;
    text-align: center;
}
#footer ul {
    padding: 0px;
}
#footer li {
    list-style-type: none;
}
#footer a {
    color: #172123;
}

/* RESOURCE HERO */
#resourceHero {
    max-width: 100%;
    background-color: var(--blue);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: var(--white);
}
#resourceHero img{
    max-width: 90vw;
}
#resourceHero h1 {
    font-size: 2em;
    font-weight: 600;
    margin: 0px;
    padding-top: 5vh;
}
#resourceHero p {
    width: 90vw;
    margin: auto;
    padding-bottom: 5vh;
}
#resourceGrid h2 {
    text-align: center;
}
#resourceGrid ul {
    padding-left: 10vw;
}
#resourceGrid li{
    list-style-type: none;
    padding: 5px 0px;
    text-decoration: underline;
}
#resourceGrid a {
    color: var(--black);
    
}
hr {
    width: 75vw;
    color: var(--green);
}

/* SERVICES */

#serviceHero {
    background-image:  linear-gradient(rgba(250, 250, 250, 0.6), rgba(250, 250, 250, 0.6)), url(../img/pexels-tim-samuel-6697294.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10vh 0px;
    text-align: center;
}
#serviceHero h1 {
    margin: 0px;
    color: var(--black);
    font-size: 2em;
    font-weight: 600;
}
#serviceList {
    margin: auto 5vw;
}
#hireGrid {
    margin: auto 5vw;
}
#quickbooks {
    background-color: var(--blue);
    color: var(--white);
    margin-top: 50px;
    padding: 5vw
}
#steps{
    background-color: #f9f9f9;
    padding: 50px 5vw;
}
#steps ul{
    padding: 0px 30px;
}
#servCall{
    margin: auto 5vw
}
/* Contact */

#servContact {
    background-color: var(--blue);
    color: var(--white);
    text-align: center;
}
#servContact h2 {
    margin-top: 5vh;
    color: var(--white)
}
#servContact div {
    text-align: left;
    margin: auto;
}
#blurb {
    max-width: 90%;
}
#servContact ul :nth-child(3) {
    margin-bottom: 5vh;
}
#servContact li {
    list-style-type: none;
    margin: 10px 0px;
}
#servContact a {
    color: var(--white);
}
#servContact svg {
    margin-right: 10px;
}
#servContact ul {
    padding-left: 0px;
}
#serviceList hr {
    width: auto;
}

/* Medium devices (landscape tablets, 750px and up) */
@media only screen and (min-width: 750px) {

    #navcontacts {
        font-size: 2em;
    }
    #navcontacts a {
        margin-top: 2.5vh;
    }
    #navcontacts a svg {
        width: 100%;
    }
    #navcontacts path {
        width: 75%
    }
    .dropdown-content a {
        padding: 12px 100px;
        font-size: 1.5em;
    }
    /* Landing */
    #welcomeHero img {
        width: 60vw;
    }
    #about h2 {
        font-size: 2em;
    }
    #about img {
        width: 60vw;
    }
    #about p {
        color: var(--black);
        margin: auto;
        padding: auto;
        font-size: 1.25em
    }
    #about p:first-of-type {
        margin-bottom: 25px;
    }
    #blurb p{
        text-align: center;
        max-width: 800px;
        font-size: 1.1em;
    }
    #contactSectionLinks {
        font-size: 1.15em;
    }
    /* Resource Grid */

    #resourceGrid {
        grid-template-columns: 1fr 1fr;
    }
    #resourceGrid div {
        width: 50vw;
    }
    #resourceGrid ul {
        text-align: center;
        padding: 1vh 5vw;
    }
    #resourceGrid li{
        list-style-type: none;
        margin: 5px 0px;
    }

    /* Services */
    #serviceList {
        margin: auto;
        width: 50%;
    }
    #hireGrid {
        width: 700px;
        margin: auto;
    }
    #hireGrid h2 {
        text-align: center;
    }
    #hireGrid div {
        width: 700px;
        margin: 0px auto;
    }
    #quickbooks {
        width: 70vw;
        padding: 2.5vh 15vw;
    }
    #steps {
        padding: 50px 0px;
        margin: 0px;
    }
    #steps h2 {
        margin: auto;
        margin-bottom: 25px;
        width: 600px;
    }
    #steps ul {
        width: 700px;
        margin: auto
    }
    #servCall {
        margin: 50px 0px;
    }
    #servCall p {
        margin: 1em auto;
        width: 600px
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    /* Nav bar changes */
    .navList {
        float: none;
    display: block;
    text-align: left;
    margin-top: 2.5vh;
    }
    .navList a {
        font-size: 1.45em;
    }
    #servLink {
        margin-right: 2.5vw;
        margin-left: 5vw;
    }
    #resLink {
        margin-left: 2.5vw;
        margin-right: 5vw;
    }
    .navList a:hover {
        color: black
    }
    #navcontacts a {
        margin-top: 1.5vh;
    }
    .dropbtn {
        display: none;
    }

    /* Landing */

    #welcomeHero img {
        width: auto;
        height: 45vh
    }
    #about {
        grid-template-columns: 1fr 1fr;
        width: 800px;
        margin: 5vh auto;
    }
    #about :first-child {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    #about img {
        max-width: 350px;
    }
    #about :nth-child(3){
        margin: auto;
    }
}